@import "variables.scss";

.menu {
  padding: 22px 25px 29px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.45) 0%,
      rgba(255, 255, 255, 0.45) 100%
    ),
    #184655;
  border: 2px solid rgba(10, 10, 10, 0.25);
  filter: drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2));
  background-blend-mode: soft-light, normal;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #fff;
  width: 750px;
  max-width: 100%;
  height: 100vh;
  transform: translateX(-100%);
  transition: 0.5s;

  @media (max-width: $mobile) {
    padding: 26px 10px 20px;
  }

  &_active {
    transform: translateX(0);
  }

  &__closer {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    background-color: #fff;
    opacity: 0.2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: opacity 0.5s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &__top {
    display: flex;
    margin-bottom: 25px;
    padding-inline: 95px;

    @media (max-width: $mobile) {
      padding-inline: 0;
      margin-bottom: 27px;
    }
  }

  &__progress {
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 20px;
    line-height: 36px;
    position: relative;
    margin-right: 35px;
    flex-grow: 1;

    @media (max-width: $mobile) {
      font-size: 16px;
    }

    &__count {
      font-weight: 700;
    }

    &__line {
      position: relative;
      height: 5px;
      background: transparentize(#ffffff, 0.9);
      border-radius: 5px;
      margin-top: 15px;
      flex-shrink: 0;

      @media (max-width: $mobile) {
        margin-top: 5px;
      }

      &__back {
        position: absolute;
        height: 100%;
        background: #23fa45;
        border-radius: 5px;
      }
    }
  }

  &__body {
    padding: 3px;
    border-radius: 8px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(24, 110, 136, 1) 100%
      ),
      #0a2e39;
    overflow: hidden;
    display: flex;

    &__inner {
      background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.25) 0%,
          rgba(255, 255, 255, 0.25) 100%
        ),
        #0a2e39;
      background-blend-mode: soft-light, normal;
      border-radius: 8px;
      padding: 31px 95px 35px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      width: 100%;

      @media (max-width: $mobile) {
        padding-left: 10px;
        padding-right: 5px;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #000000;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #054d65;
        border-radius: 8px;
      }
    }
  }

  &__item {
    $this: &;
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    text-decoration: none;

    @media (max-width: $mobile) {
      font-size: 16px;
    }

    &_gray {
      opacity: 0.3;
    }

    &_active {
      color: #6bb0cc;

      &:not(#{$this}_game) {
        svg {
          fill: #6bb0cc;

          path {
            stroke: inherit;
          }
        }
      }

      svg path {
        stroke: #6bb0cc;
      }
    }

    &:hover {
      #{$this}__link {
        text-decoration: underline;
      }
    }

    &__icon {
      margin-right: 20px;
      display: flex;

      @media (max-width: $mobile) {
        margin-right: 5px;
      }

      svg {
        width: 23px;
      }
    }

    &__arrow {
      margin-left: 20px;
      display: flex;

      @media (max-width: $mobile) {
        display: none;
      }

      svg {
        width: 16px;
      }
    }
  }

  &__item + &__item {
    margin-top: 20px;
  }

  &__bottom {
    font-size: 14px;
    line-height: 16px;
    color: transparentize(#ffffff, 0.5);
    margin-top: 29px;

    @media (max-width: $mobile) {
      margin-top: 20px;
    }

    &__items {
      margin-top: 5px;
      display: flex;

      @media (max-width: $mobile) {
        flex-wrap: wrap;
        margin-top: 15px;
      }

      > * {
        margin-bottom: 0;
        margin-right: 10px;
      }

      > * + * {
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid transparentize(#ffffff, 0.9);

        @media (max-width: $mobile) {
          margin-left: 0;
          padding-left: 0;
          border-left: none;
        }
      }

      a {
        color: inherit;
      }
    }

    &__links {
      color: #fff;
    }
  }
}
