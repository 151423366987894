@import "variables";

.slide {
  @media (max-width: $mobile) {
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.game__rocket {
  width: 182px;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: $mobile) {
    width: 66px;
  }
}
