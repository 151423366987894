@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -55px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #de6b72 0%, #a74046 100%);
  padding: 95px 100px 95px 300px;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px 15px 30px;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__image {
    height: calc(100% - 38px);
    position: absolute;
    top: 32px;
    left: -28px;
    z-index: 1;
    width: 221px;
    object-fit: cover;

    @media (max-width: $mobile) {
      position: static;
      height: 214px;
      width: auto;
      margin-top: 129px;
      margin-left: 40px;
      display: block;
    }
  }

  &__thermometer {
    position: absolute;
    left: 232px;
    width: 30px;
    top: 89px;

    @media (max-width: $mobile) {
      width: 25px;
      bottom: 30px;
      left: 220px;
      top: unset;
      display: block;
    }
  }
}
