@import "variables";

.slide {
  padding-top: 92px;
  padding-bottom: 116px;

  @media (max-width: $mobile) {
    padding-top: 15px;
    padding-bottom: 90px;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.block_tv {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 41px;

  @media (max-width: $mobile) {
    margin-top: 36px;
    width: 260px;
  }

  &__img {
    width: 475px;

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  &__inner {
    padding: 60px 148px 65px 104px;
    font-size: 48px;
    line-height: 61px;
    color: #2666a0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $mobile) {
      padding: 54px 94px 55px 75px;
      span {
        display: none;
      }
    }
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #26659f 56.25%,
      #5b9fdf 100%
    );
    border-radius: 150px;
    width: 160px;
    height: 160px;
    margin-top: 20px;
    position: relative;
    transition: 0.5s;
    cursor: pointer;

    @media (max-width: $mobile) {
      width: 90px;
      height: 90px;
      margin-top: 0;
    }

    @media (hover: hover) {
      &:hover {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #9dd0ff 56.25%,
          #5b9fdf 100%
        );
      }
    }

    svg {
      width: 114px;
      height: 114px;

      @media (max-width: $mobile) {
        width: 54px;
        height: 54px;
      }
    }
  }
}

.block_video {
  padding-top: 80px;
  width: 100vw;
  max-width: 100%;
}
