@import "variables";

.slide {
  padding-top: 152px;

  @media (max-width: $mobile) {
    padding-top: 0;
  }
}

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__iceCream {
    position: absolute;
    right: 11px;
    top: -137px;
    transform: rotate(45deg);
    height: 165px;
  }
}

.block_factory {
  height: 885px;
  margin-left: -100px;
  margin-right: -100px;
  margin-top: -290px;
  width: calc(100% + 200px);
  position: relative;

  padding-bottom: 165px;

  @media (max-width: $mobile) {
    height: 635px;
    padding-bottom: 0;
  }

  &__image {
    height: 720px;
    display: flex;
    align-items: flex-end;
    background: linear-gradient(
      180deg,
      rgba(188, 213, 250, 0) 0%,
      #bcd5fa 55.21%
    );

    img {
      width: 100%;
    }

    @media (max-width: $mobile) {
      height: 450px;
    }
  }

  &__roby {
    position: absolute;
    left: 112px;
    bottom: 120px;
    z-index: 1;

    img {
      width: 188px;
      height: 306px;
      object-fit: cover;

      @media (max-width: $mobile) {
        width: 65px;
        height: 106px;
      }
    }

    &__text {
      width: 340px;
      position: absolute;
      top: 46px;
      right: -40px;
      transform: translateX(100%);

      @media (max-width: $mobile) {
        width: 140px;
        right: -7px;
        top: 20px;
      }
    }
  }

  &__varya {
    width: 146px;
    position: absolute;
    right: 98px;
    transform: scaleX(-1);
    bottom: 120px;
    z-index: 1;

    @media (max-width: $mobile) {
      height: 106px;
      width: 51px;
      right: 108px;
    }
  }

  &::after {
    content: "";
    height: 165px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, #3170c6 0%, rgba(65, 132, 222, 0) 100%);
  }
}

.block_tv {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: -35px;

  @media (max-width: $mobile) {
    & > img {
      width: 260px;
      height: 200px;
    }
    margin-bottom: 15px;
  }

  &__inner {
    padding: 93px 224px 92px 180px;
    font-size: 48px;
    line-height: 61px;
    color: #2666a0;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: $mobile) {
      padding: 0;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__play {
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #26659f 56.25%,
      #5b9fdf 100%
    );
    border-radius: 150px;
    width: 216px;
    height: 216px;
    margin-top: 20px;
    position: relative;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #9dd0ff 56.25%,
        #5b9fdf 100%
      );
    }

    svg {
      width: 130px;
      height: 130px;

      @media (max-width: $mobile) {
        width: 47px;
        height: 47px;
      }
    }

    @media (max-width: $mobile) {
      width: 90px;
      height: 90px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
}

.block_video {
  padding-top: 80px;
  width: 100vw;
  max-width: 100%;
}
