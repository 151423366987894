@import "variables";

.slide {
  @media (max-width: $mobile) {
    padding-top: 15px;
  }
}

.text {
  @media (max-width: $mobile) {
    padding-inline: 15px;
  }
}

.white {
  position: relative;
  margin-top: 94px;

  @media (max-width: $mobile) {
    margin-top: 30px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_interesting {
  background: linear-gradient(180deg, #da7dfb 0%, #b442ea 100%);
  padding: 115px 414px 55px 256px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 48px;
    line-height: 61px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }

  &__varya {
    position: absolute;
    height: 343px;
    top: 32px;
    left: 49px;

    @media (max-width: $mobile) {
      position: relative;
      height: 199px;
      margin-top: 20px;
      top: unset;
      left: unset;
      z-index: 1;
    }
  }

  &__analyzer {
    width: 486px;
    position: absolute;
    top: 50%;
    right: -102px;
    transform: translateY(-50%);

    @media (max-width: $mobile) {
      position: static;
      width: 286px;
      display: block;
      transform: translateX(-15px);
      margin: -68px auto -112px;
    }
  }
}
