@import "variables";

.game {
  padding: 115px 100px;
  background: #0b438c;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: $mobile) {
    padding: 122px 13px 120px;
    flex-direction: column;
    min-height: calc(100vh - 180px);
  }

  > * {
    position: relative;
  }

  &__explanation {
    position: absolute;
    right: 112px;
    top: 115px;
    width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      top: unset;
      width: calc(100% - 20px);
      justify-content: center;
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 120px;
      margin-bottom: 10px;

      @media (max-width: $mobile) {
        width: 44px;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.direction {
  width: 810px;
  margin-bottom: 2px;
  align-self: center;
  transition: opacity 0.5s, visibility 0.5s;
  margin-top: 35px;

  @media (max-width: $mobile) {
    width: 100%;
    max-width: 470px;
    margin-top: 15px;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.items {
  $this: &;
  display: flex;
  position: relative;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &_targets {
    #{$this}__item {
      position: relative;
      z-index: 1;

      &__image {
        background: linear-gradient(180deg, #cfb5ff 0%, #cdccfb 100%);
      }

      &__image_empty {
        background: #455a64;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        font-weight: 300;
        font-size: 64px;
        line-height: 75px;
        color: transparentize(#ffffff, 0.7);
        border: 1px solid #ffffff;

        @media (max-width: $mobile) {
          padding: 8px;
        }

        img {
          width: 58px;
          height: 98px;
          object-fit: contain;

          @media (max-width: $mobile) {
            width: auto;
            height: 100%;
          }
        }
      }
    }

    #{$this}__item + #{$this}__item {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: -5px;
        transform: translate(-100%, -50%);
        background: url("./img/arrow.png") center no-repeat;
        background-size: contain;
        width: 20px;
        height: 8px;

        @media (max-width: $mobile) {
          background: url("./img/arrow_mobile.png") center no-repeat;
          width: 6px;
          height: 8px;
          left: -1px;
          background-size: contain;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: -25px;
      left: 0;
      width: 100%;
      height: 47px;
      background-image: url("./img/conveyor.png");
      background-size: contain;
      background-repeat: no-repeat;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    &_finish {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      justify-content: center;

      @media (max-width: $mobile) {
        width: calc(100% - 26px);
      }

      &:after {
        background-image: url("./img/conveyor-big.png");
        background-position-x: center;
      }

      #{$this}__item__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(5, 255, 0, 0.6),
            rgba(5, 255, 0, 0.6)
          );
        }
      }
    }
  }

  &_list {
    #{$this}__item {
      $that: &;

      @media (max-width: $mobile) {
        width: calc((100% - 40px) / 5);
      }

      &__image {
        cursor: pointer;

        &:hover {
          border-color: #fff;
          border-width: 1px;
        }
      }

      &:not(&_correct):hover {
        padding: 5px;
      }
    }
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    width: 170px;
    height: 170px;
    transition: padding 0.5s;

    @media (max-width: $mobile) {
      width: calc((100% - 30px) / 4);
      max-width: 150px;
      aspect-ratio: 1;
      height: auto;
      flex-shrink: 0;
      padding: 2px;

      &:first-child {
        #{$this}__tooltip {
          span {
            left: -10px;
            transform: translate(0, -100%);

            &::after {
              left: 10px;
              transform: translate(0, 100%);
            }
          }
        }
      }

      &:nth-child(2) {
        #{$this}__tooltip {
          span {
            left: -40px;
            transform: translate(0, -100%);

            &::after {
              left: 40px;
              transform: translate(0, 100%);
            }
          }
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__tooltip {
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #474b61;
      white-space: nowrap;
      cursor: pointer;
      z-index: 1;

      @media (max-width: $mobile) {
        top: 2px;
        left: 2px;
        display: flex;

        svg {
          width: 10px;
          height: 10px;
        }
      }

      span {
        background: #ffffff;
        border-radius: 10px;
        padding: 5px;
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translate(-50%, -100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #ffffff transparent transparent transparent;
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: inherit;
        }
      }
    }

    @media (max-width: $mobile) {
      &:hover {
        #{$this}__tooltip {
          span {
            opacity: 1;
            visibility: inherit;
          }
        }
      }
    }

    & > div,
    & > div > span {
      width: 100%;
      height: 100%;
      display: block;
    }

    & > div > div {
      @media (max-width: $mobile) {
        height: 47px;
      }
    }

    &__image {
      position: relative;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: border-color 1s, border-width 1s;
      align-items: center;
      background: #455a64;
      border: 1px solid #ffffff;
      padding: 10px;

      @media (max-width: $mobile) {
        padding: 3px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__item + &__item {
    margin-left: 30px;

    @media (max-width: $mobile) {
      margin-left: 10px;
    }
  }
}

.finish {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  &__cat {
    position: relative;
    margin-bottom: 57px;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 274px;
      height: 274px;
      border: 1px dashed #ffffff;
      border-radius: 50%;
      opacity: 0.2;
      animation: rotate 20s linear infinite;
    }

    img {
      width: 246px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      position: relative;
    }
  }

  &__cards {
    display: flex;

    &__item {
      $this: &;

      &__image {
        cursor: default;
      }

      &:hover {
        padding: 10px !important;

        #{$this}__image {
          border: none;
        }
      }
    }

    &__item + &__item {
      margin-left: 30px;
    }
  }

  &_visible {
    opacity: 1;
    visibility: visible;
  }

  @keyframes rotate {
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
