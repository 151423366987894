@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 102px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -44px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.factory {
  background-color: #139089;

  &__varya {
    position: absolute;
    height: 119.4%;
    left: 50%;
    bottom: -26.27%;
    transform: translateX(-50%);
  }
}

.block_bottom {
  background: linear-gradient(180deg, #40b5ae 0%, #08716b 100%);
  padding: 55px 414px 45px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__harry {
    width: 337px;
    position: absolute;
    bottom: -42px;
    right: 38px;

    @media (max-width: $mobile) {
      position: static;
      width: 217px;
      display: block;
      transform: none;
      margin: 49px auto -57px;
    }
  }
}
