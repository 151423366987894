@import "variables";

@font-face {
  font-family: "Amatic SC";
  src: local("./assets/fonts/AmaticSc/Amatic SC Regular"),
    local("./assets/fonts/AmaticSc/AmaticSC-Regular"),
    url("./assets/fonts/AmaticSc/Amaticscregular.woff2") format("woff2"),
    url("./assets/fonts/AmaticSc/Amaticscregular.woff") format("woff"),
    url("./assets/fonts/AmaticSc/Amaticscregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Amatic SC";
  src: local("./assets/fonts/AmaticSc/Amatic SC Bold"), local("AmaticSC-Bold"),
    url("./assets/fonts/AmaticSc/Amaticscbold.woff2") format("woff2"),
    url("./assets/fonts/AmaticSc/Amaticscbold.woff") format("woff"),
    url("./assets/fonts/AmaticSc/Amaticscbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Thin"), local("Roboto-Thin"),
    url("./assets/fonts/Roboto/Robotothin.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotothin.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotothin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Thin Italic"),
    local("Roboto-ThinItalic"),
    url("./assets/fonts/Roboto/Robotothinitalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotothinitalic.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotothinitalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Light"), local("Roboto-Light"),
    url("./assets/fonts/Roboto/Robotolight.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotolight.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotolight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Light Italic"),
    local("Roboto-LightItalic"),
    url("./assets/fonts/Roboto/Robotolightitalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotolightitalic.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotolightitalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto"), local("Roboto-Regular"),
    url("./assets/fonts/Roboto/Roboto.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Roboto.woff") format("woff"),
    url("./assets/fonts/Roboto/Roboto.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Italic"), local("Roboto-Italic"),
    url("./assets/fonts/Roboto/Robotoitalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotoitalic.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotoitalic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Medium"), local("Roboto-Medium"),
    url("./assets/fonts/Roboto/Robotomedium.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotomedium.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotomedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Medium Italic"),
    local("Roboto-MediumItalic"),
    url("./assets/fonts/Roboto/Robotomediumitalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotomediumitalic.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotomediumitalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Bold"), local("Roboto-Bold"),
    url("./assets/fonts/Roboto/Robotobold.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotobold.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotobold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Bold Italic"),
    local("Roboto-BoldItalic"),
    url("./assets/fonts/Roboto/Robotobolditalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotobolditalic.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotobolditalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Black"), local("Roboto-Black"),
    url("./assets/fonts/Roboto/Robotoblack.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotoblack.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotoblack.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: local("./assets/fonts/Roboto/Roboto Black Italic"),
    local("Roboto-BlackItalic"),
    url("./assets/fonts/Roboto/Robotoblackitalic.woff2") format("woff2"),
    url("./assets/fonts/Roboto/Robotoblackitalic.woff") format("woff"),
    url("./assets/fonts/Roboto/Robotoblackitalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  font-family: "Roboto", sans-serif;
}

body * {
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
  }

  &_menu {
    &::before {
      opacity: 0.4;
      visibility: visible;
    }
  }
}

.main-wrapper {
  flex-grow: 1;
  padding-top: 190px;
  padding-bottom: 150px;

  @media (max-width: $mobile) {
    padding-top: 80px;
    padding-bottom: 0;
  }
}

.game {
  &__container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;

    @media (max-width: $mobile) {
      padding: 0 15px;
      margin-bottom: 105px;
    }
  }

  &__wrapper {
    overflow: hidden;
    border-radius: 10px;
  }
}

.footer {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: $mobile) {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      #000000 100%
    ) !important;
    min-height: auto !important;
  }

  > * {
    transform: translateY(28px);

    @media (max-width: $mobile) {
      transform: translateY(-13px);
    }

    & + * {
      margin-left: 50px;
      margin-bottom: -28px;
      @media (max-width: $mobile) {
        margin-left: 34px;
      }
    }
  }

  &_beforeGame {
    @media (max-width: $mobile) {
      justify-content: flex-end;
      padding-right: 21px;

      > * + * {
        margin-left: 36px;
      }
    }
  }
}

.block {
  border-radius: 10px;
}

.title {
  font-family: "Amatic SC";
  font-weight: 700;
}

.text-cloud {
  padding: 25px;
  font-size: 18px;
  line-height: 30px;
  color: #474b61;
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 10px;

  @media (max-width: $mobile) {
    font-size: 14px;
    line-height: 18px;
    padding: 5px;
  }

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #ffffff transparent transparent;
    top: 25px;
    left: 1px;
    transform: translateX(-100%);
    position: absolute;

    @media (max-width: $mobile) {
      border-width: 5px 5px 5px 0;
      top: 11px;
    }
  }
}
