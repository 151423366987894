@import "variables";

.slide {
  padding-top: 136px;

  @media (max-width: $mobile) {
    padding-top: 0;
  }
}

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.factory {
  height: 440px;
}

.block_bottom {
  background: linear-gradient(180deg, #78dfff 0%, #1b98bf 100%);
  padding: 55px 400px 45px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__car {
    width: 414px;
    position: absolute;
    bottom: -64px;
    right: -59px;

    @media (max-width: $mobile) {
      position: static;
      width: 286px;
      display: block;
      margin: 30px auto -33px;
    }
  }
}
