@import "variables";

.wrapper {
  position: relative;
  user-select: none;

  &__background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    object-fit: cover;
  }
}

.background {
  &__image {
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: soft-light;
    opacity: 0.2;
    z-index: 3;
    transform: matrix(1, 0, 0, -1, 0, 0) translateY(8%);
    animation: backimage infinite ease-in-out 5s;
  }

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }

  @keyframes backimage {
    0% {
      transform: matrix(1, 0, 0, -1, 0, 0) translateY(8%);
    }

    50% {
      transform: matrix(1, 0, 0, -1, 0, 0) translateY(10%);
    }

    100% {
      transform: matrix(1, 0, 0, -1, 0, 0) translateY(8%);
    }
  }
}

.footer {
  background-image: url("assets/img/bottom.png");
  background-repeat: no-repeat;
  background-size: auto 77px;
  background-position: center bottom;
  min-height: 77px;
}

.game {
  &__container {
    position: relative;

    &::before {
      content: "";
      background-image: url("assets/img/head.png");
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 102px;
      transform: translateY(-60px);
      z-index: 100;

      @media (max-width: $mobile) {
        display: none;
      }
    }
  }

  &__wrapper {
    border: 2px solid #ffffff;
    background: #5da0de;
    color: #fff;
  }

  &__body {
    position: relative;
  }
}

.slide {
  padding: 92px 100px 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    padding: 0;
  }
}
