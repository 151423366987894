@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -55px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #a8a8a8 0%, #373737 100%);
  padding: 116px 414px 115px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__varya {
    height: 389px;
    position: absolute;
    top: 56px;
    right: 70px;
    transform: scaleX(-1);

    @media (max-width: $mobile) {
      position: static;
      height: 300px;
      margin: 25px auto -33px;
      display: block;
    }
  }
}
