@import "variables";

.slide {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding-bottom: 20px !important;
    font-size: 16px;
    line-height: 24px;
  }

  > * + * {
    margin-top: 30px;

    @media (max-width: $mobile) {
      margin-top: 20px;
    }
  }
}

.title {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;

  a {
    color: inherit;
  }

  @media (max-width: $mobile) {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
}

.subtitle {
  margin-top: 36px;
  font-weight: 700;

  @media (max-width: $mobile) {
    margin-top: 24px;
  }
}

.list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  border-bottom: 1px solid transparentize(#ffffff, 0.8);

  &__item {
    width: 200px;
    display: flex;
    flex-direction: column;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;

    &__image {
      border: 1px solid transparentize(#ffffff, 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 100%;
      height: 200px;
      margin-bottom: 15px;

      @media (max-width: $mobile) {
        height: 122px;
      }
    }

    &:nth-child(even) {
      img {
        transform: rotate(-30deg);
      }
    }

    img {
      max-width: 100%;
      transform: rotate(30deg);

      @media (max-width: $mobile) {
        max-width: 60%;
      }
    }

    & > p {
      font-size: 16px;
      line-height: 20px;
    }

    @media (max-width: $mobile) {
      width: 122px;
      flex-wrap: wrap;
      margin-bottom: 20px;
    }
  }

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    padding-bottom: 0px;
  }
}
