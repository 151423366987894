@import "variables";

.dialog-mobile {
  margin-top: 25px;

  span {
    color: white;
  }

  & > div + div {
    margin-top: 20px;
  }

  & > div > div {
    background-color: transparent;
    color: white;
  }
}

.slide {
  @media (max-width: $mobile) {
    padding: 15px;
  }
}

.image {
  height: 547px;

  &__varya {
    position: absolute;
    height: 50.82%;
    left: 17.83%;
    bottom: -9.5%;
  }

  &__roby {
    position: absolute;
    height: 60.14%;
    left: 31.03%;
    bottom: -12.19%;
  }

  &__vitya {
    position: absolute;
    height: 51.37%;
    right: 5.77%;
    transform: scaleX(-1);
    bottom: -7.82%;
  }
}

.games {
  display: grid;
  grid-gap: 55px 50px;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 50px;

  @media (max-width: $mobile) {
    grid-gap: 31px 10px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    $this: &;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Amatic SC";
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-decoration: none;

    &_completed {
      #{$this}__icon {
        border: 2px solid #23fa45;
      }

      #{$this}__icon {
        img {
          opacity: 0.3;
        }
      }

      #{$this}__chapter {
        opacity: 0.3;
      }

      #{$this}__title {
        opacity: 0.3;
        text-decoration: line-through;
      }
    }

    &:nth-last-child(2) {
      grid-column-start: 2;
      grid-column-end: 3;

      @media (max-width: $mobile) {
        grid-column-start: 1;
        grid-column-end: 2;
      }
    }

    &:nth-last-child {
      grid-column-start: 3;
      grid-column-end: 4;
    }

    &__icon {
      width: 200px;
      height: 200px;
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.4);
      position: relative;
      margin-bottom: 15px;

      @media (max-width: $mobile) {
        width: 122px;
        height: 122px;
      }

      &__check {
        position: absolute;
        left: 25%;
        top: 25%;
        transform: translate(-100%, -100%);
        display: flex;
      }

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 124px;
        height: 124px;
        border: 1px solid transparentize(#ffffff, 0.8);
        border-radius: 50%;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s, visibility 0.5s;

        @media (max-width: $mobile) {
          width: 75px;
          height: 75px;
        }
      }

      img {
        position: relative;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s, visibility 0.5s;

        @media (max-width: $mobile) {
          transform: scale(0.6);
        }
      }
    }

    &__play {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s, visibility 0.5s;
    }

    &:hover {
      #{$this}__play {
        opacity: 1;
        visibility: visible;
      }

      #{$this}__icon {
        &:before,
        img {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &__chapter {
      font-size: 20px;
      line-height: 25px;
    }

    &__title {
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.buttons {
  display: flex;
  margin-top: 50px;
  justify-content: center;

  &__button {
    border: 2px solid transparentize(#ffffff, 0.8);
    border-radius: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 96px;
    text-shadow: 0px 2px 4px rgba(91, 26, 26, 0.14),
      0px 3px 4px rgba(123, 12, 12, 0.12), 0px 1px 5px rgba(136, 13, 13, 0.2);
    cursor: pointer;
    background-color: transparent;
    color: #fff;
    transition: border-color 0.5s;

    @media (max-width: $mobile) {
      height: 50px;
      padding-inline: 19px;
    }

    &:not(:disabled):hover {
      border-color: #ffffff;
    }

    &_back {
      svg {
        transform: scaleX(-1);
      }
    }

    &_big {
      &:disabled {
        color: transparentize(#ffffff, 0.7);
        cursor: default;
      }

      flex-grow: 1;
      font-size: 48px;
      line-height: 61px;
    }
  }

  &__button + &__button {
    margin-left: 40px;

    @media (max-width: $mobile) {
      margin-left: 10px;
      font-size: 36px;
      line-height: 45px;
    }
  }
}

.modal {
  width: 600px;
  max-width: 100%;
  font-size: 36px;
  line-height: 45px;
  font-family: "Amatic SC";
  font-weight: 700;
  color: #7e4700;

  @media (max-width: $mobile) {
    font-size: 24px;
    line-height: 30px;
    width: 100%;
  }

  img {
    width: 100%;

    @media (max-width: $mobile) {
      width: auto;
      max-width: 100%;
    }
  }

  &__body {
    position: absolute;
    inset: 0;
    padding: 100px 100px 80px;

    @media (max-width: $mobile) {
      padding: 94px 30px;
    }
  }

  &__title {
    font-size: 78px;
    line-height: 98px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
    }
  }

  .buttons {
    margin-top: 70px;

    @media (max-width: $mobile) {
      margin-top: 40px;
    }

    &__button {
      height: 60px;
      padding-inline: 48px;
      border-color: #7e4600;

      @media (max-width: $mobile) {
        padding-inline: 19px;
        height: 50px;
      }

      svg {
        fill: #7e4600;
      }

      &_big {
        font-size: 24px;
        line-height: 30px;
        margin-left: 20px;
        background: #4792d8;
        color: #fff;
        border: none;
        white-space: nowrap;

        @media (max-width: $mobile) {
          margin-left: 10px;
        }

        svg {
          margin-right: 20px;
        }
      }
    }
  }
}
