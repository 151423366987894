@import "variables";

.varya {
  position: fixed !important;
  bottom: 16px;
  z-index: 1000;
  left: 29.95%;

  @media (max-width: $mobile) {
    bottom: 7px;
  }

  &_static,
  &_start {
    > img {
      height: 240px;

      @media (max-width: $mobile) {
        height: 80px;
      }
    }

    > span {
      bottom: 0;
      right: -35px;

      @media (max-width: $mobile) {
        right: -5px;
      }
    }
  }

  &_failure {
    bottom: 10px;

    @media (max-width: $mobile) {
      bottom: 6px;
    }

    > img {
      height: 252px;

      @media (max-width: $mobile) {
        height: 84px;
      }
    }

    > span {
      bottom: 6px;
      right: -33px;

      @media (max-width: $mobile) {
        right: -4px;
        bottom: 2px;
      }
    }
  }

  &_finish {
    left: 27.71%;
    bottom: 7px;

    @media (max-width: $mobile) {
      left: 0;
      bottom: 5px;
    }

    > img {
      height: 255px;

      @media (max-width: $mobile) {
        height: 85px;
      }
    }

    > span {
      bottom: 9px;
      right: -13px;
      padding-right: 60px;

      @media (max-width: $mobile) {
        right: 2px;
        bottom: 2px;
        padding-right: 0;
      }
    }
  }

  > span {
    background: #f6f6f7;
    position: absolute;
    transform: translate(100%, 0);
    width: 587px;
    font-size: 32px;
    line-height: 40px;

    @media (max-width: $mobile) {
      width: 154px;
      font-size: 14px;
      line-height: 18px;

      &:before {
        top: 50%;
        transform: translate(-100%, -50%);
      }
    }
  }
}
