@import "variables";

.game {
  padding: 125px 100px 165px;
  background: #352072;

  @media (max-width: $mobile) {
    padding: 34px 13px 87px;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.cards {
  $this: &;
  display: grid;
  grid-template-columns: repeat(4, 170px);
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &_finish {
    position: absolute;
    grid-template-columns: repeat(3, 170px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $mobile) {
      display: flex;
      grid-row-gap: unset;
      grid-column-gap: unset;
      justify-content: space-around;
      margin: 0 auto;
      max-width: 470px;
      width: calc(100% - 26px);

      #{$this}__item {
        &:nth-last-child(2) {
          margin-right: inherit;
        }
      }
    }
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    height: 170px;
    transition: padding 0.5s;

    @media (max-width: $mobile) {
      width: calc((100% - 20px) / 3);
      max-width: 150px;
      aspect-ratio: 1;
      height: auto;
      flex-shrink: 0;
      padding: 2px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(3n),
      &:nth-last-child(2) {
        margin-right: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    @media (hover: hover) {
      &:not(&_correct):hover {
        padding: 5px;

        #{$this}__image {
          border-color: #fff;
          border-width: 1px;
        }
      }
    }

    &_correct {
      #{$this}__image {
        cursor: default;
        background: linear-gradient(
          180deg,
          #3ea02e 0%,
          #aaff9c 100%
        ) !important;

        animation: success 1s ease-in-out 1;
      }

      @keyframes success {
        0% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }

        50% {
          box-shadow: 0px 0px 10px 10px #ffffff;
        }

        100% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }
      }
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__title {
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      text-align: center;
    }

    &__image {
      position: relative;
      border-radius: 10px;
      padding: 35px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      border: 0 solid transparent;
      transition: border-color 1s, border-width 1s;
      cursor: pointer;
      text-align: center;
      font-size: 24px;
      line-height: 30px;
      padding: 10px 0;
      background: linear-gradient(180deg, #527fa4 0%, #8ecdfc 100%);

      @media (max-width: $mobile) {
        font-size: 16px;
        line-height: 16px;
        padding: 0;
        justify-content: flex-end;
      }

      img {
        height: 90px;
        width: 90px;
        object-fit: contain;

        @media (max-width: $mobile) {
          width: 47%;
          height: auto;
          aspect-ratio: 1;
        }
      }
    }
  }

  &__last {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      width: calc(100% - 30px);
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    img {
      width: 48px;
      margin-bottom: 46px;

      @media (max-width: $mobile) {
        width: 20px;
        margin-bottom: 0;
        margin-right: 29px;
      }
    }
  }
}
