@import "variables";

.slide {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding-inline: 0 !important;
  }
}

.whiteBlock {
  margin-top: 30px;

  @media (max-width: $mobile) {
    margin-top: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.text {
  margin-top: 50px;

  @media (max-width: $mobile) {
    margin-top: 20px;
  }
}

.image {
  height: 433px;

  @media (max-width: $mobile) {
    height: auto;
  }
}
