@import "variables";

.game {
  padding: 145px 100px 145px;
  background: #0b438c;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: $mobile) {
    padding: 53px 13px 46px;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  > * {
    position: relative;
  }

  &__explanation {
    position: absolute;
    right: 111px;
    top: 141px;
    width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      top: 18px;
      width: calc(100% - 40px);
      transform: translateX(-50%);
      justify-content: center;
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 48px;
      margin-bottom: 46px;

      @media (max-width: $mobile) {
        width: 20px;
        margin-bottom: 0;
        margin-right: 15px;
      }
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 170px);
  gap: 30px;
  position: relative;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    width: 170px;
    height: 170px;
    transition: padding 0.5s;

    @media (max-width: $mobile) {
      width: calc((100% - 20px) / 3);
      max-width: 150px;
      aspect-ratio: 1;
      height: auto;
      flex-shrink: 0;
      padding: 2px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:first-child,
      &:nth-child(4n) {
        #{$this}__tooltip {
          span {
            left: -10px;
            transform: translate(0, -100%);

            &::after {
              left: 10px;
              transform: translate(0, 100%);
            }
          }
        }
      }

      &:nth-child(3n) {
        #{$this}__tooltip {
          span {
            left: 20px;
            transform: translate(-100%, -100%);

            &::after {
              left: unset;
              right: 0;
              transform: translate(-100%, 100%);
            }
          }
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -1px;
      left: -1px;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    &:not(&_correct):hover {
      padding: 5px;

      #{$this}__image {
        border-color: #fff;
        border-width: 1px;
      }
    }

    &_correct {
      #{$this}__image {
        cursor: default;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(62, 160, 46, 0.6) 0%,
            rgba(170, 255, 156, 0.6) 100%
          );
        }

        animation: success 1s ease-in-out 1;
      }

      @keyframes success {
        0% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }

        50% {
          box-shadow: 0px 0px 10px 10px #ffffff;
        }

        100% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }
      }
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__tooltip {
      position: absolute;
      top: 20px;
      right: 20px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #474b61;
      white-space: nowrap;
      cursor: pointer;
      z-index: 1;

      @media (max-width: $mobile) {
        top: 5px;
        right: 5px;
        white-space: unset;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      span {
        background: #ffffff;
        border-radius: 10px;
        padding: 5px;
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translate(-50%, -100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        @media (max-width: $mobile) {
          width: max-content;
          max-width: 70vw;
        }

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #ffffff transparent transparent transparent;
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: inherit;
        }
      }
    }

    @media (max-width: $mobile) {
      &:hover {
        #{$this}__tooltip {
          span {
            opacity: 1;
            visibility: inherit;
          }
        }
      }
    }

    &__image {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 0 solid transparent;
      transition: border-color 1s, border-width 1s;
      cursor: pointer;
      text-align: center;
      align-items: center;
      font-size: 24px;
      line-height: 30px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.finish {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: $mobile) {
    width: calc(100% - 30px);
  }

  &__cat {
    position: relative;
    margin-bottom: 57px;

    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 274px;
      height: 274px;
      border: 1px dashed #ffffff;
      border-radius: 50%;
      opacity: 0.2;
      animation: rotate 20s linear infinite;

      @media (max-width: $mobile) {
        width: 160px;
        height: 160px;
      }
    }

    img {
      width: 246px;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      position: relative;

      @media (max-width: $mobile) {
        width: 143px;
      }
    }
  }

  &__cards {
    display: flex;

    &__item {
      $this: &;

      &__image {
        cursor: default;
      }

      &:hover {
        padding: 10px !important;

        @media (max-width: $mobile) {
          padding: 2px !important;
        }

        #{$this}__image {
          border: none;
        }
      }
    }

    &__item + &__item {
      margin-left: 30px;

      @media (max-width: $mobile) {
        margin-left: 0;
      }
    }
  }

  &_visible {
    opacity: 1;
    visibility: visible;
  }

  @keyframes rotate {
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}

.cat {
  margin-left: 30px;
  width: 170px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    width: 143px;
    margin-left: 0;
    margin-top: 5px;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }
}
