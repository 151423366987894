@import "variables";

.slide {
  padding: 81px 100px 129px;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    margin-bottom: 65px;
    color: #ffffff;

    @media (max-width: $mobile) {
      margin-bottom: 30px;
    }
  }
}
