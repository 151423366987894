@import "variables";

.block_white {
  padding: 94px 100px 50px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(71, 75, 97, 0.2);

  > * + * {
    margin-top: 30px;

    @media (max-width: $mobile) {
      margin-top: 20px;
    }
  }

  @media (max-width: $mobile) {
    padding: 15px;
  }
}
