@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -55px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.image {
  &:after {
    content: "";
    position: absolute;
    background: linear-gradient(
      0deg,
      rgba(255, 92, 0, 0.7),
      rgba(255, 92, 0, 0.7)
    );
    width: 100%;
    height: 100%;
  }

  &__roby {
    position: absolute;
    bottom: -20.89%;
    left: 8.05%;
    height: 102.39%;
  }
}

.block_bottom {
  background: linear-gradient(180deg, #5da0de 0%, #276ea6 100%);
  padding: 95px 414px 65px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__wires {
    width: 688px;
    position: absolute;
    top: -256px;
    right: -261px;

    @media (max-width: $mobile) {
      position: static;
      margin-top: 47px;
      margin-bottom: -347px;
      display: block;
    }
  }
}
