@import "variables";

.wrapper {
  position: relative;
  user-select: none;

  &__background {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      background-blend-mode: color, normal;
      transform: matrix(-1, 0, 0, 1, 0, 0);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(24, 70, 85, 0) 0%, #184655 100%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
}

.background {
  &__image {
    min-width: 100%;
    min-height: 120%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    mix-blend-mode: soft-light;
    opacity: 0.2;
    z-index: 3;
    transform: translateY(-8%);
    animation: backimage infinite ease-in-out 5s;
  }

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }

  @keyframes backimage {
    0% {
      transform: translateY(-8%);
    }

    50% {
      transform: translateY(-10%);
    }

    100% {
      transform: translateY(-8%);
    }
  }
}

.slide {
  padding: 20px 100px 50px;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    padding: 20px 15px 0px;
  }
}

.game {
  &__container {
    position: relative;
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #184655;

    &__light_top {
      position: absolute;
      transform: rotate(45deg);
      right: -387px;
      top: -828px;
      filter: blur(100px);
      opacity: 0.3;
    }

    &__light_bottom {
      position: absolute;
      transform: rotate(45deg);
      top: 320px;
      left: -433px;
      opacity: 0.3;
      filter: blur(100px);
    }
  }

  &__wrapper {
    border: 2px solid #ffffff;
    background: #5da0de;
    color: #fff;
  }

  &__body {
    position: relative;
  }
}

.progress {
  background-color: transparentize(#000000, 0.6);
  padding: 29px 98px;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  position: relative;

  @media (max-width: $mobile) {
    flex-direction: column;
    padding: 10px 13px;

    & span {
      font-size: 16px;
    }
  }

  &__count {
    font-weight: 700;
  }

  &__line {
    flex-grow: 1;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    height: 5px;
    background: transparentize(#ffffff, 0.9);
    border-radius: 5px;

    &__back {
      position: absolute;
      height: 100%;
      background: #23fa45;
      border-radius: 5px;
    }

    @media (max-width: $mobile) {
      width: 100%;
      margin-top: 5px;
      margin-bottom: 15px;
      max-width: 260px;
    }
  }

  &__button {
    font-weight: 700;
    font-size: 16px;
    line-height: 36px;
    padding: 0 18px;
    border: 1px solid transparentize(#ffffff, 0.8);
    border-radius: 36px;
    cursor: pointer;
    transition: border-color 0.5s;
    text-decoration: none;
    color: inherit;

    &:hover {
      border-color: #ffffff;
    }

    @media (max-width: $mobile) {
      width: 100%;
      max-width: 260px;
      text-align: center;
    }
  }
}

.footer {
  background-image: url("assets/footer.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
}

.navigatorDot {
  background: #184655;
}
