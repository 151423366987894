@import "variables";

.game {
  padding: 115px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: $mobile) {
    padding: 107px 13px;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
  }

  &__explanation {
    margin: 74px auto 57px;
    width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;
    position: relative;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      width: calc(100% - 30px);
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
      margin: 0;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 48px;
      margin-bottom: 46px;

      @media (max-width: $mobile) {
        width: 20px;
        margin-bottom: 0;
        margin-right: 29px;
      }
    }
  }
}

.background {
  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.items {
  $this: &;
  display: flex;
  position: relative;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &_finish {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: $mobile) {
      width: calc(100% - 30px);
    }
  }

  &_list {
    &__item {
      $this: &;
      cursor: pointer;

      &:hover {
        padding: 5px;

        #{$this}__image {
          border-color: #fff;
          border-width: 1px;
        }
      }
    }
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    width: 170px;
    height: 170px;

    @media (max-width: $mobile) {
      width: calc((100% - 20px) / 3);
      max-width: 150px;
      aspect-ratio: 1;
      height: auto;
      flex-shrink: 0;
      padding: 2px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(3n),
      &:last-child {
        margin-right: 0;
      }
    }

    transition: padding 0.5s;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    &_correct {
      #{$this}__image {
        cursor: default;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(51, 255, 0, 0.6),
            rgba(51, 255, 0, 0.6)
          );
        }

        animation: success 1s ease-in-out 1;
      }

      @keyframes success {
        0% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }

        50% {
          box-shadow: 0px 0px 10px 10px #ffffff;
        }

        100% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }
      }
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__image {
      position: relative;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 0 solid transparent;
      transition: border-color 1s, border-width 1s;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      font-size: 24px;
      line-height: 30px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
      }
    }

    &__title {
      position: absolute;
      top: 10px;
      width: 100%;
      text-align: center;
      color: #474b61;

      @media (max-width: $mobile) {
        display: none;
      }
    }

    &__tooltip {
      position: absolute;
      top: 5px;
      left: 5px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #474b61;
      white-space: nowrap;
      cursor: pointer;
      z-index: 1;
      display: none;

      @media (max-width: $mobile) {
        display: flex;
      }

      svg {
        width: 10px;
        height: 10px;
      }

      span {
        background: #ffffff;
        border-radius: 10px;
        padding: 5px;
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translate(-50%, -100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #ffffff transparent transparent transparent;
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: inherit;
        }
      }
    }

    &:hover {
      #{$this}__tooltip {
        span {
          opacity: 1;
          visibility: inherit;
        }
      }
    }

    &:first-child,
    &:nth-child(4n) {
      #{$this}__tooltip {
        span {
          left: -10px;
          transform: translate(0, -100%);

          &::after {
            left: 10px;
            transform: translate(0, 100%);
          }
        }
      }
    }

    &:nth-child(3n),
    &:nth-child(5n) {
      #{$this}__tooltip {
        span {
          left: 20px;
          transform: translate(-100%, -100%);

          &::after {
            left: unset;
            right: 0;
            transform: translate(-100%, 100%);
          }
        }
      }
    }
  }

  &__item + &__item {
    margin-left: 30px;

    @media (max-width: $mobile) {
      margin-left: 0;
    }
  }
}
