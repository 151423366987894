@import "variables";

.roby {
  position: fixed !important;
  bottom: 0;
  z-index: 1000;

  @media (max-width: $mobile) {
    bottom: 7px;
  }

  &_static {
    left: 29.95%;

    @media (max-width: $mobile) {
      left: 95px;
    }

    > img {
      height: 220px;

      @media (max-width: $mobile) {
        height: 80px;
      }
    }

    > span {
      bottom: 0;
      right: -10px;

      @media (max-width: $mobile) {
        right: 5px;
        bottom: 17px;
        width: max-content !important;
        max-width: calc(100vw - 169px) !important;
      }
    }
  }

  &_failure {
    left: 29.16%;
    bottom: -10px;

    @media (max-width: $mobile) {
      bottom: 3px;
      left: 90px;
    }

    > img {
      height: 235px;

      @media (max-width: $mobile) {
        height: 85px;
      }
    }

    > span {
      bottom: 10px;
      right: 7px;

      @media (max-width: $mobile) {
        right: 12px;
        bottom: 21px;
        width: max-content !important;
        max-width: calc(100vw - 169px) !important;
      }
    }
  }

  &_finish {
    left: 28.23%;

    @media (max-width: $mobile) {
      left: 0;
    }

    > img {
      height: 220px;

      @media (max-width: $mobile) {
        height: 80px;
      }
    }

    > span {
      bottom: 0;
      right: -12px;
      padding-right: 60px;

      @media (max-width: $mobile) {
        right: 2px;
        bottom: 2px;
        padding-right: 0;
        width: max-content !important;
        max-width: calc(100vw - 180px) !important;
      }
    }
  }

  > span {
    background: #f6f6f7;
    position: absolute;
    transform: translate(100%, 0);
    width: 587px;
    font-size: 40px;
    line-height: 50px;

    @media (max-width: $mobile) {
      font-size: 14px;
      line-height: 18px;

      &:before {
        top: 50%;
        transform: translate(-100%, -50%);
      }
    }
  }
}
