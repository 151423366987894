@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 42px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -26px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.image {
  height: 490px;
  margin-bottom: 30px;
}

.block_bottom {
  background: linear-gradient(180deg, #de6b72 0%, #a74046 100%);
  padding: 95px 414px 95px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__pizza {
    width: 520px;
    position: absolute;
    top: -87px;
    right: -93px;

    @media (max-width: $mobile) {
      position: static;
      width: 360px;
      margin: 36px auto -60px;
      display: block;
    }
  }
}
