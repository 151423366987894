@import "variables";

.text {
  font-family: "Amatic SC";
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: #474b61;

  @media (max-width: $mobile) {
    font-size: 20px;
    line-height: 25px;
  }
}
