@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__iceCream {
    position: absolute;
    left: 8px;
    top: -137px;
    transform: rotate(-45deg);
    width: 58px;
  }
}

.interesting {
  background: linear-gradient(180deg, #4e92d0 0%, #26649e 100%);
  padding: 70px 100px 100px 300px;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px;
    border-radius: 10px !important;
    font-weight: 400;
  }

  &__text {
    margin-bottom: 15px;

    @media (max-width: $mobile) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &__image {
    height: 343px;
    position: absolute;
    top: 32px;
    left: -28px;
    z-index: 1;

    @media (max-width: $mobile) {
      position: relative;
      display: block;
      left: unset;
      top: unset;
      height: 200px;
      margin: 0 auto;
    }
  }
}
