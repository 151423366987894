@import "variables";

.game {
  padding: 215px 100px 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #6a185d;

  @media (max-width: $mobile) {
    padding: 33px 13px 84px;
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
  }

  &__explanation {
    width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;
    position: relative;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      width: calc(100% - 30px);
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
      margin: 0;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 48px;
      margin-bottom: 46px;

      @media (max-width: $mobile) {
        width: 20px;
        margin-bottom: 0;
        margin-right: 29px;
      }
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(5, 170px);
  gap: 30px;
  position: relative;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 310px;
    width: 100%;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    height: 170px;
    transition: padding 0.5s;

    @media (max-width: $mobile) {
      width: calc((100% - 10px) / 2);
      max-width: 150px;
      aspect-ratio: 1;
      height: auto;
      flex-shrink: 0;
      padding: 2px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    &:not(&_correct):not(&_last):hover {
      padding: 5px;

      #{$this}__image {
        border-color: #fff;
        border-width: 1px;
      }
    }

    &_last {
      &::before {
        border-style: solid;
        opacity: 1;
      }

      #{$this}__image {
        cursor: default;
      }
    }

    &_correct {
      #{$this}__image {
        cursor: default;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(62, 160, 46, 0.6),
            rgba(62, 160, 46, 0.6)
          );
        }

        animation: success 1s ease-in-out 1;
      }

      @keyframes success {
        0% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }

        50% {
          box-shadow: 0px 0px 10px 10px #ffffff;
        }

        100% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }
      }
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__image {
      position: relative;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      border: 0 solid transparent;
      transition: border-color 1s, border-width 1s;
      cursor: pointer;
      text-align: center;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__last {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

    img {
      width: 48px;
      margin-bottom: 46px;
    }
  }
}

.finish {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 356px;
  max-width: 100%;
}
