@import "variables";

.slide {
  padding-top: 152px;

  @media (max-width: $mobile) {
    padding-top: 0;
  }
}

.title {
  @media (max-width: $mobile) {
    font-weight: 700 !important;
    font-size: 48px !important;
    line-height: 60px !important;
  }
}

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__iceCream {
    position: absolute;
    left: 8px;
    top: -140px;
    transform: rotate(-45deg);
    width: 58px;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.interesting {
  background: linear-gradient(180deg, #da7dfb 0%, #be42ea 100%);
  padding: 50px 100px 180px 300px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px;
    margin-top: 50px;
  }

  & > p:nth-child(n) {
    @media (max-width: $mobile) {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 180px;
    }
  }

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(190, 66, 234, 0) 0%, #be42ea 100%);
    border-radius: 0px 0px 10px 10px;
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__image {
    height: calc(100% - 38px);
    position: absolute;
    top: 32px;
    left: 26px;
    z-index: 1;

    @media (max-width: $mobile) {
      height: 171px;
      top: unset;
      bottom: 11px;
      left: calc(50% - 40px);
    }
  }

  &__scoops {
    position: absolute;
    bottom: 19px;
    right: 256px;

    @media (max-width: $mobile) {
      right: calc(50% - 120px);
      bottom: 11px;
      height: 45px;
    }
  }
}

.block_bottom {
  background: #276ea6;
  background: linear-gradient(180deg, #5da0de 0%, #276ea6 100%);
  color: #fff;
  padding: 60px 300px 110px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;

  @media (max-width: $mobile) {
    padding: 15px;
  }

  & > p:nth-child(2n) {
    margin-bottom: 255px;
  }

  &__image1 {
    position: absolute;
    left: 88px;
    top: 36px;
    width: 182px;
    transform: rotate(-30deg);

    @media (max-width: $mobile) {
      width: 150px;
      top: unset;
      bottom: -80px;
      left: -20px;
    }
  }

  &__image2 {
    position: absolute;
    right: 88px;
    top: 36px;
    width: 182px;
    transform: rotate(30deg);

    @media (max-width: $mobile) {
      width: 150px;
      top: unset;
      bottom: -80px;
      right: -30px;
    }
  }
}
