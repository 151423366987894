@import "variables";

.swiper {
  position: relative;

  @media (max-width: $mobile) {
    height: 280px;
  }

  &-wrapper {
    align-items: center;
  }

  &-slide {
    position: relative;
    display: flex;
    justify-content: center;
    height: 342px;
    align-items: center;

    img {
      max-width: 100%;
      border-radius: 50%;
      object-fit: cover;
      width: 200px;
      height: 200px;
      flex-shrink: 0;
    }

    &-active {
      text-align: center;
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }

      &::before {
        width: 320px;
        height: 320px;
        border: 1px dashed #ffffff;
        animation: rotateScale infinite ease-in-out 10s;

        @media (max-width: $mobile) {
          width: 260px;
          height: 260px;
        }
      }

      &::after {
        width: 85px;
        height: 85px;
        border: 1px solid #ffffff;
        backdrop-filter: blur(20px);
        background: url("/assets/images/icons/search.svg") center no-repeat
          rgba(71, 75, 97, 0.3);
        background-size: 30px;
        transition: 1s;

        @media (max-width: $mobile) {
          width: 70px;
          height: 70px;
          background-size: 25px;
        }
      }

      &:hover {
        &:after {
          transform: translate(-50%, -50%) scale(1.2);
        }
      }

      img {
        width: 300px;
        height: 300px;

        @media (max-width: $mobile) {
          width: 244px;
          height: 244px;
        }
      }
    }
  }
}

@keyframes rotateScale {
  0% {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.0625) rotate(90deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
  }
}
