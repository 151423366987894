@import "variables";

.slide {
  @media (max-width: $mobile) {
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.game__star {
  width: 330px;
  transform: translate(-50%, -50%);
  z-index: 1;
  animation: rotate 20s ease-in-out infinite;

  @media (max-width: $mobile) {
    width: 100px;
  }
}

.game__iceCream {
  width: 151px;
  transform: translate(-50%, -50%) rotate(30deg);
  z-index: 2;
  animation: scale 20s ease-in-out infinite;

  @media (max-width: $mobile) {
    width: 47px;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(30deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(390deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(30deg);
  }
}

@keyframes scale {
  0% {
    transform: translate(-50%, -50%) rotate(30deg) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) rotate(30deg) scale(1.22);
  }

  100% {
    transform: translate(-50%, -50%) rotate(30deg) scale(1);
  }
}
