@import "variables";

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: var(--back-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 1s;

  @media (max-width: $mobile) {
    background: transparent;
    width: 50px;
    height: 50px;
  }

  &:hover {
    background-color: var(--back-color-hover);

    @media (max-width: $mobile) {
      background: transparent;
    }
  }

  &_prev {
    svg {
      transform: rotate(-180deg);
    }

    left: 50px;

    @media (max-width: 768px) {
      top: 20%;
      left: 65px !important;
      transform: translate(-100%, -100%);
    }

    @media (max-width: $mobile) {
      left: 14%;
    }
  }

  &_next {
    right: 50px;

    @media (max-width: 768px) {
      top: 20%;
      right: 65px !important;
      transform: translate(100%, -100%);
    }

    @media (max-width: $mobile) {
      right: 14%;
    }
  }
}

.block_slider {
  width: 900px;
  margin: 30px auto 0;

  @media (max-width: $mobile) {
    margin: 0;
    margin-top: 10px;
    width: 100%;
  }
}

.slider_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;

  &__arrow {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 1;
    }

    svg {
      transform: rotate(180deg);
      width: 30px;
      height: 30px;
    }

    &_prev {
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.8;
      left: 0;
    }

    &_next {
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.8;
      right: 0;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
