@import "variables";

.wrapper {
  position: relative;
  height: 335px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;

  @media (max-width: $mobile) {
    width: 100%;
    height: auto !important;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
