@import "variables";

.slide {
  @media (max-width: $mobile) {
    min-height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.game__printer {
  width: 224px;
  transform: translate(-50%, -50%);
  z-index: 2;

  @media (max-width: $mobile) {
    width: 101px;
  }
}

.title {
  font-size: 64px;
  line-height: 81px;

  @media (max-width: $mobile) {
    font-size: 36px;
    line-height: 45px;
  }
}
