@import "variables";

.slide {
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;
  // padding-bottom: 0 !important;

  @media (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    padding-bottom: 45px !important;
  }
}

.title {
  font-weight: 700;
  font-size: 78px;
  line-height: 98px;
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 10px;
  }
}

.subtitle {
  font-weight: 700;
  margin-bottom: 36px;

  @media (max-width: $mobile) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
  }
}

.image {
  height: 442px;
  margin-top: 30px;

  &__varya {
    position: absolute;
    height: 63.18%;
    left: 17.83%;
    bottom: -8.82%;
  }

  &__roby {
    position: absolute;
    height: 74.43%;
    left: 31.03%;
    bottom: -10.4%;
  }

  &__vitya {
    position: absolute;
    height: 63.57%;
    right: 5.77%;
    transform: scaleX(-1);
    bottom: -6.79%;
  }
}

.dialog-mobile {
  margin-top: 25px;

  span {
    color: white;
  }

  & > div + div {
    margin-top: 20px;
  }

  & > div > div {
    background-color: transparent;
    color: white;
  }
}
