@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 68px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -47px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #5da0de 0%, #276ea6 100%);
  padding: 70px 100px 130px 300px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 11px;
  }

  &__title {
    font-size: 48px;
    line-height: 61px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }

  &__varya {
    height: 343px;
    position: absolute;
    top: 32px;
    left: 28px;

    @media (max-width: $mobile) {
      position: relative;
      top: 0;
      left: 0;
      height: 171px;
      display: block;
      margin: 21px auto 0;
      z-index: 1;
    }
  }

  &__cars {
    position: absolute;
    width: 690px;
    bottom: -37px;
    right: -20px;

    @media (max-width: $mobile) {
      width: 470px;
      bottom: -26px;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(38, 100, 158, 0) 0%, #26649e 100%);
    border-radius: 0px 0px 10px 10px;
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
