@import "variables";

.game {
  padding: 125px 25px 135px;
  background: #8000bc;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px 13px 84px;
    min-height: calc(100vh - 180px);
    justify-content: center;
  }

  > * {
    position: relative;
  }

  &__explanation {
    position: absolute;
    left: 113px;
    bottom: 135px;
    width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      width: calc(100% - 80px);
      justify-content: center;
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 120px;
      margin-bottom: 10px;

      @media (max-width: $mobile) {
        width: 44px;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.cards {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-bottom: 1px;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
    width: 260px;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__item + &__item {
    margin-left: 20px;

    @media (max-width: $mobile) {
      margin-left: 10px;
    }
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    width: 170px;
    height: 170px;
    transition: padding 0.5s;
    flex-shrink: 0;

    @media (max-width: $mobile) {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
      padding: 2px;

      &:nth-child(4n) {
        margin-left: 0;
      }
    }

    & > div,
    & > div > span {
      width: 100%;
      height: 100%;
      display: block;
    }

    & > div > div {
      width: 150px;
      height: 150px;
      display: block;

      @media (max-width: $mobile) {
        width: 76px;
        height: 76px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    @media (hover: hover) {
      &:hover {
        padding: 5px;

        #{$this}__image {
          border-color: #fff;
          border-width: 1px;
        }
      }
    }

    &_candrop {
      &::before {
        opacity: 1;
      }
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__tooltip {
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #474b61;
      white-space: nowrap;
      cursor: pointer;
      z-index: 1;

      @media (max-width: $mobile) {
        top: 3px;
        left: 3px;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      span {
        background: #ffffff;
        border-radius: 10px;
        padding: 10px;
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translate(-50%, -100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 10px 0 10px;
          border-color: #ffffff transparent transparent transparent;
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: inherit;
        }
      }
    }

    &__image {
      position: relative;
      border-radius: 10px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      height: 100%;
      border: 0 solid transparent;
      transition: border-color 1s, border-width 1s;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      @media (max-width: $mobile) {
        z-index: 1;
      }

      img {
        max-width: 80px;

        @media (max-width: $mobile) {
          min-height: 40px;
        }
      }
    }
  }
}

.direction {
  width: 945px;
  margin-bottom: 2px;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    width: 180px;
    margin-top: -99px;
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.pot {
  width: 234px;
  transition: 0.5s;

  @media (max-width: $mobile) {
    width: 111px;
  }

  &_shadow {
    filter: drop-shadow(0px 0px 10px #ffffff);
  }

  &_hovered {
    filter: drop-shadow(0px 0px 20px #ffffff);
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.finish {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transition: 2s;
  transform: translate(-50%, -50%);

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 436px;
    height: 436px;
    border: 1px dashed #ffffff;
    border-radius: 50%;
    animation: rotate 20s linear infinite;

    @media (max-width: $mobile) {
      width: 210px;
      height: 210px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 298px;
    height: 298px;
    background: #ffffff;
    mix-blend-mode: normal;
    border-radius: 50%;
    box-shadow: 0px 0px 30px 30px rgba(255, 255, 255, 0.5);

    @media (max-width: $mobile) {
      width: 140px;
      height: 140px;
    }
  }

  img {
    width: 446px;
    position: relative;
    z-index: 1;

    @media (max-width: $mobile) {
      width: 210px;
    }
  }

  &_visible {
    opacity: 1;
    visibility: visible;
  }

  @keyframes rotate {
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
