@import "variables";

.slide {
  padding: 184px 600px 164px 100px;

  @media (max-width: $mobile) {
    padding: 15px;
  }
}

.factory {
  padding: 5px 15px;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #ffffff;
  backdrop-filter: blur(20px);
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    display: inline-block;
    font-size: 14px;
    line-height: 36px;
    margin: 0 auto;
    padding: 0 15px;
    text-align: center;
  }
}

.game__title {
  font-family: "Amatic SC";
  font-style: normal;
  font-weight: 700;
  font-size: 78px;
  line-height: 98px;
  color: #ffffff;
  margin-bottom: 30px;

  @media (max-width: $mobile) {
    font-size: 44px;
    line-height: 55px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.game__description {
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;

  @media (max-width: $mobile) {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px;
  }
}

.game__image {
  position: absolute;
  top: 50%;
  right: 335px;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;

  @media (max-width: $mobile) {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto;
    transform: none;
    height: 150px;
  }

  * {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &_circle {
    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 270px;
      height: 270px;
      border-radius: 50%;
      border: 1px dashed #ffffff;
      animation: scaleRotate 20s ease-in-out infinite;

      @media (max-width: $mobile) {
        width: 84px;
        height: 84px;
      }
    }
  }

  &_shadow {
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-radius: 50%;
      box-shadow: 0px 0px 104px 104px #ffffff;
      z-index: 1;

      @media (max-width: $mobile) {
        box-shadow: 0px 0px 30px 30px #ffffff;
      }
    }
  }
}

@keyframes scaleRotate {
  0% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) rotate(360deg) scale(1.47);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0) scale(1);
  }
}
