@import "variables";

.nextButton {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100px, -50%);
  z-index: 1;
  font-size: 36px;
  line-height: 45px;

  @media (max-width: $mobile) {
    right: -15px;
    transform: translate(100%, -50%);
    font-size: 24px;
    line-height: 36px;

    img {
      display: none;
    }
  }

  &__text {
    font-size: 24px;
    line-height: 30px;
    display: block;

    @media (max-width: $mobile) {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.prevButton {
  position: fixed !important;
  bottom: 14px;
  left: 21px;
}
