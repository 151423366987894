@import "variables";

.varya {
  position: fixed;
  z-index: 1000;
  left: 3.23%;
  bottom: 5px;

  img {
    height: 192px;

    @media (max-width: $mobile) {
      height: 80px;
    }
  }

  span {
    position: absolute;
    right: -10px;
    top: 0;
    transform: translate(100%, 0);
    font-size: 32px;
    line-height: 40px;

    @media (max-width: $mobile) {
      font-size: 14px;
      line-height: 18px;
      right: -5px;
      top: 10px;
    }

    &::before {
      top: 35px;

      @media (max-width: $mobile) {
        top: 11px;
      }
    }
  }
}
