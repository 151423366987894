@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 68px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -47px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #5da0de 0%, #276ea6 100%);
  padding: 100px 100px 100px 300px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 40px;
  }

  &__title {
    font-size: 48px;
    line-height: 61px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }

  &__varya {
    height: 343px;
    position: absolute;
    top: 32px;
    left: 28px;

    @media (max-width: $mobile) {
      position: static;
      height: 171px;
      margin: 35px auto 0;
      display: block;
    }
  }
}
