@import "variables";

.slide {
  @media (max-width: $mobile) {
    padding-top: 15px;
  }
}

.text {
  @media (max-width: $mobile) {
    padding-inline: 15px;
  }
}

.white {
  position: relative;
  margin-top: 95px;

  @media (max-width: $mobile) {
    margin-top: 30px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.factory_varya {
  position: absolute;
  height: 119.4%;
  bottom: -26.26%;
  left: 8.83%;
}
