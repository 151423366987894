@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -55px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #4e92d0 0%, #26649e 100%);
  padding: 50px 100px 180px 300px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 11px;
  }

  &__title {
    font-size: 48px;
    line-height: 61px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }
  }

  &__roby {
    height: 343px;
    position: absolute;
    top: 32px;
    left: -28px;

    @media (max-width: $mobile) {
      position: relative;
      top: unset;
      left: unset;
      height: 171px;
      display: block;
      transform: none;
      margin: 13px auto 0;
      z-index: 1;
    }
  }

  &__shoes {
    position: absolute;
    width: 841px;
    bottom: -17px;
    right: -84px;

    @media (max-width: $mobile) {
      width: 445px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 11px;
      right: unset;
    }
  }

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(38, 100, 158, 0) 0%, #26649e 100%);
    border-radius: 0px 0px 10px 10px;
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
