@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -35px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.text {
  margin-top: 50px;
  font-size: 78px;
  line-height: 98px;
  color: #fff;

  @media (max-width: $mobile) {
    font-size: 32px;
    line-height: 40px;
    padding-inline: 15px;
    padding-bottom: 15px;
    margin-top: 25px;
  }
}
