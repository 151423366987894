@import "variables";

.slide {
  @media (max-width: $mobile) {
    min-height: calc(100vh - 180px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
