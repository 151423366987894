@import "variables";

.slide {
  padding-top: 152px;

  @media (max-width: $mobile) {
    padding-top: 0;
  }
}

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__iceCream {
    position: absolute;
    left: 8px;
    top: -140px;
    transform: rotate(-45deg);
    width: 58px;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.image {
  height: 440px;
}

.interesting {
  background: linear-gradient(180deg, #4e92d0 0%, #26649e 100%);
  padding: 50px 100px 180px 300px;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px;
    border-radius: 10px !important;
    font-weight: 400;
  }

  &__text {
    margin-bottom: 15px;

    @media (max-width: $mobile) {
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
    }
  }

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(38, 100, 158, 0) 0%, #26649e 100%);
    border-radius: 0px 0px 10px 10px;
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__image {
    height: calc(100% - 38px);
    position: absolute;
    top: 32px;
    left: -28px;
    z-index: 1;

    @media (max-width: $mobile) {
      top: unset;
      bottom: 0;
      left: calc(50% - 68px);
      height: 170px;
    }
  }

  &__iceCreams {
    position: absolute;
    bottom: 19px;
    right: 47px;

    @media (max-width: $mobile) {
      position: relative;
      display: block;
      bottom: -20px;
      margin: 0 auto;
      right: unset;
    }
  }
}
