@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 68px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -47px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.image {
  height: 450px;
}
