@import "variables";

.game {
  position: relative;
  height: 660px;

  @media (max-width: $mobile) {
    height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__explanation {
    position: absolute;
    top: 50%;
    left: 114px;
    transform: translateY(-50%);
    width: 144px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      top: unset;
      bottom: 15px;
      width: calc(100% - 80px);
      justify-content: center;
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 48px;
      margin-bottom: 46px;

      @media (max-width: $mobile) {
        width: 20px;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dots {
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: $mobile) {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
  }
}

.rocket {
  position: absolute;
  left: 50%;
  z-index: 1;
  height: 100%;
  transform: translateX(-50%);

  @media (max-width: $mobile) {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
  }
}

.canvas {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
