@import "variables";

.game {
  padding: 125px 100px 165px;
  display: flex;
  justify-content: center;

  @media (max-width: $mobile) {
    padding: 30px 8px 106px;
    flex-direction: column;
    min-height: calc(100vh - 180px);
  }

  > * {
    position: relative;
  }

  &__explanation {
    width: 144px;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      width: calc(100% - 80px);
      justify-content: center;
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    img {
      width: 120px;
      margin-bottom: 10px;

      @media (max-width: $mobile) {
        width: 44px;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }

  &__color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #703321;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  $this: &;

  @media (max-width: $mobile) {
    width: 270px;
    margin-inline: auto;
  }

  &__row {
    display: flex;
    background: url("./img/shelf.png") center bottom no-repeat;
    background-size: contain;

    @media (max-width: $mobile) {
      justify-content: center;
    }

    > * + * {
      margin-left: 30px;

      @media (max-width: $mobile) {
        margin-left: 10px;
      }
    }
  }

  &__row + &__row {
    margin-top: 44px;

    @media (max-width: $mobile) {
      margin-top: 14px;
    }
  }

  &__cell {
    height: 85px;
    width: 170px;
    border: 1px dashed transparentize(#ffffff, 0.8);
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    transition: border 0.5s;

    @media (max-width: $mobile) {
      width: 60px;
      height: 30px;
    }

    &_hovered {
      border: 1px dashed transparentize(#ffffff, 0);
    }

    &_error {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        border-radius: 10px;
        height: 100%;
        background: linear-gradient(
          0deg,
          rgba(255, 0, 0, 0.6),
          rgba(255, 0, 0, 0.6)
        );
      }
    }

    &:not(&_error):hover {
      #{$this}__remove {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 30px;

    @media (max-width: $mobile) {
      height: 100%;

      p {
        display: none;
      }
    }

    img {
      width: 80px;
      height: 90px;
      object-fit: contain;

      @media (max-width: $mobile) {
        width: auto;
        height: 100%;
      }
    }
  }

  &__remove {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparentize(#000000, 0.8);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
    z-index: 1;
    border-radius: 10px;

    svg {
      fill: #ffffff;
      width: 50px;
      height: 50px;

      @media (max-width: $mobile) {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.side {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;

  @media (max-width: $mobile) {
    margin-right: auto;
    position: static;
  }

  &_hidden {
    display: none;
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 46px;

    @media (max-width: $mobile) {
      flex-direction: column;
      margin-bottom: 0;
    }

    &__arrow {
      width: 70px;
      margin-right: 30px;

      @media (max-width: $mobile) {
        width: auto;
        height: 20px;
        margin: 5px 0;
      }
    }
  }
}

.items {
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    flex-direction: row;
    justify-content: space-between;
    width: 270px;
  }

  &__item {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 70px;
    height: 60px;
    position: relative;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      & > div {
        @media (max-width: $mobile) {
          height: 47px;
        }
      }
    }

    @media (max-width: $mobile) {
      width: 55px;
      height: 47px;
    }

    img {
      width: 70px;
      height: 60px;
      object-fit: contain;
      cursor: pointer;

      @media (max-width: $mobile) {
        width: auto;
        height: 100%;
      }
    }
  }

  &__item + &__item {
    margin-top: 22px;

    @media (max-width: $mobile) {
      margin-top: 0;
    }
  }
}
