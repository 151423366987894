.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--color-from);
    animation: backcolor infinite 10s;
  }

  @keyframes backcolor {
    0% {
      background-color: var(--color-from);
    }

    50% {
      background-color: var(--color-to);
    }

    100% {
      background-color: var(--color-from);
    }
  }

  &__figure {
    position: absolute;
    background-blend-mode: multiply;
    z-index: 2;

    &_1 {
      width: 56.8%;
      top: 156px;
      left: -24.87%;
      transform: translate(0, 0);
      //animation: figure1 infinite ease-in-out 10s;
    }

    &_2 {
      width: 53.6%;
      right: -19.4%;
      top: -308px;
      transform: translate(0, 0);
      //animation: figure2 infinite ease-in-out 10s;
    }

    &_3 {
      width: 51.5%;
      top: 583px;
      right: -18.54%;
      transform: translate(0, 0);
      //animation: figure3 infinite ease-in-out 10s;
    }

    &_4 {
      width: 53.5%;
      top: 822px;
      left: -18.2%;
      transform: translate(0, 0);
      //animation: figure4 infinite ease-in-out 10s;
    }

    &_5 {
      width: 53.5%;
      top: 1287px;
      right: -19.4%;
      transform: translate(0, 0);
      //animation: figure5 infinite ease-in-out 10s;
    }

    @keyframes figure1 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(-41px, 41px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    @keyframes figure2 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(-29px, 64px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    @keyframes figure3 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(16px, 71px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    @keyframes figure4 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(-30px, 24px);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    @keyframes figure5 {
      0% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(18px, -41px);
      }
      100% {
        transform: translate(0, 0);
      }
    }
  }
}
