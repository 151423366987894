@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -35px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #4fb766 0%, #116523 100%);
  padding: 65px 414px 65px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__greenhouse {
    width: 488px;
    position: absolute;
    bottom: -108px;
    right: -115px;

    @media (max-width: $mobile) {
      position: static;
      width: 286px;
      margin: 61px 0 -68px 58px;
      display: block;
    }
  }
}
