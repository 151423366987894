@import "variables";

.clips {
  margin-top: -30px;
  margin-bottom: -30px;
  display: flex;
  justify-content: center;

  @media (max-width: $mobile) {
    margin-top: 50px;
    margin-bottom: 0;
  }

  &__doubled {
    padding-left: 125px;
    padding-right: 125px;
    justify-content: space-between;
  }
}

.clip {
  height: 110px;
  position: relative;
  display: inline-block;
  width: 20px;
  z-index: 2;

  @media (max-width: $mobile) {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 50%;
    transform: translateX(-50%);
    width: 8px;
    height: calc(100% - 12px);
    background-color: #d9d9d9;
    border-radius: 8px;
    z-index: 1;
  }

  &__top,
  &__bottom {
    width: 100%;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    left: 0;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #000;
      position: absolute;
      display: inline-block;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__top {
    top: 0;
  }

  &__bottom {
    bottom: 0;
  }
}
