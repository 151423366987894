@import "variables.scss";

.navigator {
  background: #ffffff;
  border-radius: 15px 0 0 15px;
  display: flex;
  flex-direction: column;
  padding: 15px 8px;
  position: fixed;
  top: 271px;
  left: calc((100vw - 1170px) / 2);
  transform: translateX(-100%);

  &__item {
    background: #4792d8;
    opacity: 0.3;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: opacity 0.5s;
    cursor: pointer;

    &:last-child {
      background: #d84747;
      @media (max-width: $mobile) {
        margin-right: 0px !important;
      }
    }

    &:not(&_active):hover {
      opacity: 0.7;
    }

    &_active {
      opacity: 1;
      cursor: default;
    }

    &:disabled {
      cursor: default;
    }

    @media (max-width: $mobile) {
      margin-right: 11px;
    }
  }

  &__item + &__item {
    margin-top: 11px;
    @media (max-width: $mobile) {
      margin-top: 0;
    }
  }

  @media (max-width: $mobile) {
    left: 50%;
    flex-direction: row;
    top: 30px;
    transform: translateX(-50%);
    border-radius: 0px 0px 15px 15px;
    padding: 8px 15px;
    z-index: 100;
    border: 1px solid transparentize(#000000, 0.9);
  }
}
