@import "variables";

.modalBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
  transition: opacity 0.5s;
}

.modal {
  position: relative;
  max-height: 90vh;
  display: flex;
  max-width: 960px;
}

.modal__body {
  overflow: auto;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modalBack_visible {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.modal__actions {
  position: absolute;
  right: 30px;
  top: 30px;
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: $mobile) {
    top: 10px;
    right: 10px;
  }
}

.modal__actions > * + * {
  margin-right: 10px;
}

.modal__close {
  cursor: pointer;

  @media (max-width: $mobile) {
    width: 20px;
    height: 20px;
  }
}
