@import "variables";

.game {
  padding: 130px 400px 130px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #000b46;

  @media (max-width: $mobile) {
    padding: 15px 13px 116px;
    min-height: calc(100vh - 180px);
    justify-content: center;
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &__light_top {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: -828px;
    left: 272px;
    transform: rotate(-45deg);
    opacity: 0.3;
    filter: blur(100px);
  }

  &__light_bottom {
    z-index: 1;
    position: absolute;
    width: 1285px;
    top: 182px;
    left: 190px;
    transform: rotate(45deg);
    opacity: 0.5;
    filter: blur(100px);
  }
}

.items {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: opacity 0.5s, visibility 0.5s;

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }

  &__item + &__item {
    margin-top: 40px;

    @media (max-width: $mobile) {
      margin-top: 10px;
    }
  }

  &__item {
    $this: &;
    padding: 15px 10px 15px 60px;
    position: relative;
    transition: padding 0.5s;
    display: flex;
    border: 1px dashed transparentize(#ffffff, 0.8);
    border-radius: 10px;
    transition: 0.5s;
    cursor: pointer;

    @media (max-width: $mobile) {
      padding: 5px;
    }

    @media (hover: hover) {
      &:not(&_error):hover {
        border: 1px solid #4caf50;

        #{$this}__check {
          border-color: #4caf50;

          &:before,
          &:after {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    &_error {
      background-color: #a52929;
    }

    &__check {
      width: 30px;
      height: 30px;
      border: 1px solid transparentize(#ffffff, 0.8);
      border-radius: 50%;
      position: absolute;
      top: 15px;
      left: 15px;
      transition: border-color 0.5s;

      @media (max-width: $mobile) {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        width: 26px;
        height: 26px;
        background-color: #4caf50;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;
      }

      &:after {
        content: "";
        background-image: url("../../../assets/images/icons/check.svg");
        background-size: 16px;
        width: 16px;
        height: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;
      }
    }

    &__text {
      font-size: 24px;
      line-height: 30px;
      text-transform: uppercase;

      @media (max-width: $mobile) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.roby {
  width: 300px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
  transition: opacity 0.5s, visibility 0.5s;

  @media (max-width: $mobile) {
    width: 58px;
    top: unset;
    bottom: 15px;
    left: 50%;
    right: unset;
    transform: translateX(-50%);
  }

  &_hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.finish {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 36px;
  line-height: 45px;
  text-align: center;

  @media (max-width: $mobile) {
    font-size: 24px;
    line-height: 30px;
    width: calc(100% - 40px);
  }

  img {
    width: 212px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      width: 124px;
    }
  }
}
