@import "variables";

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 660px;
  position: relative;

  @media (max-width: $mobile) {
    min-height: 380px;
  }
}

.arrow {
  width: 98px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 4;

  @media (max-width: $mobile) {
    display: none;
  }

  &_1 {
    animation: arrow1 infinite ease-in-out 5s;
  }

  &_2 {
    animation: arrow2 infinite ease-in-out 5s;
  }

  &_3 {
    animation: arrow3 infinite ease-in-out 5s;
  }

  &_4 {
    animation: arrow4 infinite ease-in-out 5s;
  }

  &_5 {
    animation: arrow5 infinite ease-in-out 5s;
  }

  &_6 {
    animation: arrow6 infinite ease-in-out 5s;
  }

  @keyframes arrow1 {
    0% {
      transform: translate(calc(-100% - 217px), calc(-100% - 116px))
        rotate(36.03deg);
    }

    50% {
      transform: translate(calc(-100% - 266px), calc(-100% - 165px))
        rotate(36.03deg);
    }

    100% {
      transform: translate(calc(-100% - 217px), calc(-100% - 116px))
        rotate(36.03deg);
    }
  }

  @keyframes arrow2 {
    0% {
      transform: translate(calc(-100% - 267px), -50%) rotate(4.07deg);
    }

    50% {
      transform: translate(calc(-100% - 317px), -50%) rotate(4.07deg);
    }

    100% {
      transform: translate(calc(-100% - 267px), -50%) rotate(4.07deg);
    }
  }

  @keyframes arrow3 {
    0% {
      transform: translate(calc(-100% - 217px), 115px) rotate(-25.27deg);
    }

    50% {
      transform: translate(calc(-100% - 279px), 164px) rotate(-25.27deg);
    }

    100% {
      transform: translate(calc(-100% - 217px), 115px) rotate(-25.27deg);
    }
  }

  @keyframes arrow4 {
    0% {
      transform: translate(217px, calc(-100% - 116px))
        matrix(-0.81, 0.59, 0.59, 0.81, 0, 0);
    }

    50% {
      transform: translate(266px, calc(-100% - 165px))
        matrix(-0.81, 0.59, 0.59, 0.81, 0, 0);
    }

    100% {
      transform: translate(217px, calc(-100% - 116px))
        matrix(-0.81, 0.59, 0.59, 0.81, 0, 0);
    }
  }

  @keyframes arrow5 {
    0% {
      transform: translate(267px, -50%) matrix(-1, 0.07, 0.07, 1, 0, 0);
    }

    50% {
      transform: translate(317px, -50%) matrix(-1, 0.07, 0.07, 1, 0, 0);
    }

    100% {
      transform: translate(267px, -50%) matrix(-1, 0.07, 0.07, 1, 0, 0);
    }
  }

  @keyframes arrow6 {
    0% {
      transform: translate(217px, 115px) matrix(-0.9, -0.43, -0.43, 0.9, 0, 0);
    }

    50% {
      transform: translate(279px, 164px) matrix(-0.9, -0.43, -0.43, 0.9, 0, 0);
    }

    100% {
      transform: translate(217px, 115px) matrix(-0.9, -0.43, -0.43, 0.9, 0, 0);
    }
  }
}

.image {
  position: relative;
  z-index: 3;

  &__circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 410px;
    height: 410px;
    animation: scale infinite ease-in-out 5s;

    @media (max-width: $mobile) {
      width: 260px;
      height: 260px;
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px dashed #ffffff;
      animation: rotate infinite linear 5s;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 282px;
    height: 282px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    opacity: 0.8;

    border-radius: 999px;
    animation: scaleShadow infinite ease-in-out 5s;

    @media (max-width: $mobile) {
      width: 140px;
      height: 140px;
    }
  }

  &__book {
    width: 321px;
    height: 307px;
    position: relative;
    z-index: 1;
    cursor: pointer;

    @media (max-width: $mobile) {
      width: 203px;
      height: 195px;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes scale {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.166);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes scaleShadow {
  0% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0px 0px 30px 30px #ffffff;
  }

  50% {
    transform: translate(-50%, -50%) scale(0.89);
    box-shadow: 0px 0px 50px 80px #ffffff;
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
    box-shadow: 0px 0px 30px 30px #ffffff;
  }
}

.book {
  width: 600px;

  @media (max-width: $mobile) {
    width: 300px;
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    user-select: none;
    pointer-events: none;

    svg {
      transform: rotate(180deg);
      width: 30px;
      height: 30px;

      path {
        fill: #9d6b2d;
      }
    }

    &_disable {
      opacity: 0.3;
    }

    &_prev {
      left: 37px;

      @media (max-width: $mobile) {
        left: 10px;
      }
    }

    &_next {
      right: 37px;
      transform: translateY(-50%) rotate(180deg);

      @media (max-width: $mobile) {
        right: 10px;
      }
    }
  }
}

.page {
  color: #474b61;
  font-family: "Amatic SC", sans-serif;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 38px;
  height: 100%;
  position: relative;

  &__title {
    font-size: 36px;
    line-height: 45px;
  }

  &__poststamp {
    max-width: calc(100% - 60px);
    flex-grow: 1;
    height: 0;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    @media (max-width: $mobile) {
      max-width: 100%;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      transform: rotate(-3deg);
    }

    &__text {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      margin-top: 10px;

      @media (max-width: $mobile) {
        max-width: 100%;
      }
    }
  }

  &__button {
    border: 2px solid transparentize(#474b61, 0.8);
    border-radius: 100px;
    padding: 7px 0;
    font-size: 36px;
    line-height: 45px;
    text-decoration: none;
    color: inherit;
    width: 100%;
    text-align: center;
    transition: border-color 0.5s;

    &:hover {
      border-color: #474b61;
    }
  }
}
