@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 68px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -47px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #61b9b4 20.83%, #4a68b6 100%);
  padding: 35px 414px 35px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 0;
  }

  &__title {
    font-size: 96px;
    line-height: 121px;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      font-size: 48px;
      line-height: 61px;
      margin-bottom: 20px;
    }
  }

  &__image {
    width: 505px;
    right: -143px;
    bottom: -145px;
    position: absolute;

    @media (max-width: $mobile) {
      position: static;
      margin: 32px auto 0-150px;
      display: block;
    }
  }
}
