@import "variables";

.title {
  font-size: 78px;
  line-height: 98px;
  margin-bottom: 50px;

  @media (max-width: $mobile) {
    font-weight: 700;
    font-size: 34px;
    line-height: 43px;
    padding: 0 15px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

.button {
  font-size: 64px;
  line-height: 81px;
  text-shadow: 0px 2px 4px rgba(91, 26, 26, 0.14),
    0px 3px 4px rgba(123, 12, 12, 0.12), 0px 1px 5px rgba(136, 13, 13, 0.2);
  padding: 10px;
  width: 100%;
  border: 2px solid #ffffff;
  border-radius: 100px;
  text-align: center;
  margin-top: 50px;
  cursor: pointer;

  @media (max-width: $mobile) {
    font-size: 36px;
    line-height: 45px;
    font-weight: 700;
    width: unset;
    margin: 30px auto;
    padding: 0;
    width: 260px;
  }
}
