@import "variables";

.slide {
  @media (max-width: $mobile) {
    padding-top: 15px;
  }
}

.text {
  @media (max-width: $mobile) {
    padding-inline: 15px;
  }
}

.white {
  position: relative;
  margin-top: 95px;

  @media (max-width: $mobile) {
    margin-top: 30px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #78dfff 0%, #1b98bf 100%);
  padding: 60px 40px 30px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px 15px 19px;
  }

  &__title {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 30px;

    @media (max-width: $mobile) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }

  &__image {
    height: 290px;
  }
}
