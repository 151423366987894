@import "variables.scss";

.button {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(50% 50% at 50% 50%, #4792d8 56.25%, #26649e 100%);
  border: none;
  outline: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 48px;
  line-height: 61px;
  color: #ffffff;
  font-family: "Amatic SC", sans-serif;
  cursor: pointer;

  @media (max-width: $mobile) {
    width: 71px;
    height: 71px;
    font-size: 24px;
    line-height: 30px;

    img {
      width: 11px;
      margin-top: 5px;
    }
  }

  @media (hover: hover) {
    &:not(:disabled):hover {
      &::before {
        opacity: 0.8;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    background: #fff;
    opacity: 0.3;
    box-shadow: 0px 0px 30px 30px #fff;
    transition: 0.5s;
  }

  &::after {
    content: "";
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 1px dashed #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: $mobile) {
      width: 80px;
      height: 80px;
    }
  }

  &_red {
    background: radial-gradient(
      50% 50% at 50% 50%,
      #d84747 56.25%,
      #9e2626 100%
    );
  }

  &:disabled {
    cursor: default;
    color: transparentize(#ffffff, 0.5);

    * {
      opacity: 0.5;
    }
  }
}
