@import "variables";

.game {
  padding: 125px 200px 165px;
  background: #6f5dde;

  @media (max-width: $mobile) {
    padding: 45px 13px 106px;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 180px);
  }
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("./img/background.png") center no-repeat;
    background-size: cover;
    z-index: 1;
  }

  &__color {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #6f5dde;
    animation: backcolor infinite 10s;
  }

  @keyframes backcolor {
    0% {
      background-color: #6f5dde;
    }

    50% {
      background-color: #5844d3;
    }

    100% {
      background-color: #6f5dde;
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(4, 170px);
  gap: 30px;
  position: relative;

  @media (max-width: $mobile) {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: unset;
    grid-column-gap: unset;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 470px;

    & > span {
      width: calc((100% - 20px) / 3);
      max-width: 150px;
      aspect-ratio: 1;
      height: auto;
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
        background: url("./img/arrow.png") center no-repeat;
        background-size: contain;
        width: 10px;
        height: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      &:first-child,
      &:nth-child(4n) {
        .cards__item__tooltip {
          span {
            left: -10px;
            transform: translate(0, -100%);

            &::after {
              left: 10px;
              transform: translate(0, 100%);
            }
          }
        }
      }

      &:nth-child(3n),
      &:nth-child(6n) {
        .cards__item__tooltip {
          span {
            left: 20px;
            transform: translate(-100%, -100%);

            &::after {
              left: unset;
              right: 0;
              transform: translate(-100%, 100%);
            }
          }
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 100px);
    height: 100%;
    left: -50px;
    background: url("./img/direction.png") center no-repeat;
    background-size: contain;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__item {
    $this: &;
    padding: 10px;
    position: relative;
    height: 170px;
    transition: padding 0.5s;

    @media (max-width: $mobile) {
      height: auto;
      width: 100%;
      padding: 2px;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0.2;
      border: 1px dashed #ffffff;
      border-radius: 10px;
      transition: opacity 1s;
    }

    & > div {
      width: 100%;
      height: 100%;
      display: block;

      & > span {
        width: 100%;
        height: 100%;
        display: block;
      }

      & > div {
        width: 150px;
        height: 150px;
        display: block;

        @media (max-width: $mobile) {
          width: calc((100% - 24px) / 3);
          max-width: 150px;
          aspect-ratio: 1;
          height: auto;
        }
      }
    }

    &:not(&_correct):not(&_last):hover {
      padding: 5px;

      #{$this}__image {
        border-color: #fff;
        border-width: 1px;
      }
    }

    &_candrop {
      &::before {
        opacity: 1;
      }
    }

    &_last {
      @media (max-width: $mobile) {
        width: calc((100% - 20px) / 3);
        max-width: 150px;
        aspect-ratio: 1;
        height: auto;
      }

      &::before {
        border-style: solid;
        opacity: 1;
      }

      #{$this}__image {
        cursor: default;
      }
    }

    &_correct {
      #{$this}__image {
        cursor: default;
        background: linear-gradient(
          180deg,
          #3ea02e 0%,
          #aaff9c 100%
        ) !important;

        animation: success 1s ease-in-out 1;
      }

      @keyframes success {
        0% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }

        50% {
          box-shadow: 0px 0px 10px 10px #ffffff;
        }

        100% {
          box-shadow: 0px 0px 0 0 #ffffff;
        }
      }
    }

    &_error {
      #{$this}__image {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-radius: 10px;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(255, 0, 0, 0.6),
            rgba(255, 0, 0, 0.6)
          );
        }
      }
    }

    &__tooltip {
      position: absolute;
      top: 10px;
      left: 10px;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #474b61;
      white-space: nowrap;
      cursor: pointer;
      z-index: 1;

      @media (max-width: $mobile) {
        top: 5px;
        left: 5px;

        svg {
          width: 15px;
          height: 15px;
        }
      }

      span {
        background: #ffffff;
        border-radius: 10px;
        padding: 5px;
        position: absolute;
        left: 50%;
        top: -5px;
        transform: translate(-50%, -100%);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s, visibility 0.5s;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translate(-50%, 100%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: #ffffff transparent transparent transparent;
        }
      }

      &:hover {
        span {
          opacity: 1;
          visibility: inherit;
        }
      }
    }

    @media (max-width: $mobile) {
      &:hover {
        #{$this}__tooltip {
          span {
            opacity: 1;
            visibility: inherit;
          }
        }
      }
    }

    &__image {
      position: relative;
      border-radius: 10px;
      padding: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border: 0 solid transparent;
      transition: border-color 1s, border-width 1s;
      cursor: pointer;
      background: url("./img/card-back.jpg") center no-repeat;
      background-size: cover;

      @media (max-width: $mobile) {
        padding: 18px;
      }

      img {
        max-width: 100%;
      }
    }
  }

  &__last {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    transition: opacity 0.5s, visibility 0.5s;

    @media (max-width: $mobile) {
      position: absolute;
      left: 50%;
      bottom: 15px;
      width: calc(100% - 80px);
      justify-content: center;
      transform: translateX(-50%);
      flex-direction: row;
      text-align: left;
      font-size: 12px;
    }

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }

    img {
      width: 120px;

      @media (max-width: $mobile) {
        width: 44px;
        margin-bottom: 0;
        margin-right: 20px;
      }
    }
  }
}
