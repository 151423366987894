@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 102px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -44px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.factory_roby {
  position: absolute;
  height: 102.39%;
  left: 8.05%;
  bottom: -20.89%;
}
