@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 68px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -47px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.roby {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 72.24%;

  &__image {
    height: 100%;
  }

  &__shoe {
    width: 50%;
    position: absolute;
    left: 34.53%;
    bottom: -16.12%;
  }
}
