@import "variables";

.vitya {
  position: fixed !important;
  bottom: -38px;
  z-index: 1000;
  left: 29.11%;

  @media (max-width: $mobile) {
    bottom: 0;

    img {
      transform: translateY(13px);
    }
  }

  &_static {
    > img {
      height: 293px;

      @media (max-width: $mobile) {
        height: 100px;
      }
    }

    > span {
      bottom: 38px;
      right: -35px;

      @media (max-width: $mobile) {
        bottom: 25px;
        right: -5px;
      }
    }
  }

  &_failure {
    bottom: -50px;
    left: 29%;

    @media (max-width: $mobile) {
      bottom: -4px;
    }

    > img {
      height: 319px;

      @media (max-width: $mobile) {
        height: 109px;
      }
    }

    > span {
      bottom: 50px;
      right: -11px;

      @media (max-width: $mobile) {
        bottom: 29px;
        right: 4px;
      }
    }
  }

  &_finish {
    left: 26%;

    @media (max-width: $mobile) {
      left: -10px;
    }

    > img {
      height: 299px;

      @media (max-width: $mobile) {
        height: 102px;
      }
    }

    > span {
      bottom: 38px;
      right: 37px;
      padding-right: 60px;

      @media (max-width: $mobile) {
        bottom: 25px;
        right: 14px;
        padding-right: 0;
      }
    }
  }

  > span {
    background: #f6f6f7;
    position: absolute;
    transform: translate(100%, 0);
    width: 587px;
    font-size: 32px;
    line-height: 40px;

    @media (max-width: $mobile) {
      width: 150px;
      font-size: 14px;
      line-height: 18px;

      &:before {
        top: 50%;
        transform: translate(-100%, -50%);
      }
    }
  }
}
