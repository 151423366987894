@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -55px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.varya_image {
  height: 124.77%;
  position: absolute;
  top: 15.52%;
  right: 11.95%;
  transform: scaleX(-1);
}
