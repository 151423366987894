@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 84px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -46px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_bottom {
  background: linear-gradient(180deg, #cfc98f 0%, #456958 100%);
  padding: 35px 414px 35px 40px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;
  font-weight: 300;

  @media (max-width: $mobile) {
    padding: 15px;
  }

  &__image {
    width: 708px;
    position: absolute;
    top: -136px;
    right: -194px;

    @media (max-width: $mobile) {
      top: unset;
      right: -100px;
      bottom: -20%;
      width: 460px;
    }
  }
}

.text {
  @media (max-width: $mobile) {
    margin-bottom: 213px;
  }
}

.title {
  @media (max-width: $mobile) {
    font-size: 48px !important;
    line-height: 60px !important;
  }
}
