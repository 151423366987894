@import "variables.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.45) 0%,
      rgba(255, 255, 255, 0.45) 100%
    ),
    #184655;
  background-blend-mode: soft-light, normal;
  padding: 5px 310px;
  z-index: 999;

  @media (max-width: $mobile) {
    padding: 2px 10px;
  }

  &__wrapper {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(255, 255, 255, 0.25) 100%
      ),
      #0a2e39;
    background-blend-mode: soft-light, normal;
    border-radius: 8px;
    padding: 10px 65px;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    font-family: "Amatic SC";
    display: flex;
    align-items: center;
    color: #fff;

    @media (max-width: $mobile) {
      padding: 0 10px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &__menu {
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    transition: opacity 0.5s;

    @media (max-width: $mobile) {
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.5;
    }

    svg {
      width: 22px;

      @media (max-width: $mobile) {
        width: 14px;
      }
    }
  }

  &__chapter {
    padding-left: 20px;
    border-left: 1px solid transparentize(#ffffff, 0.8);

    @media (max-width: $mobile) {
      padding-left: 0;
      border-left: none;
    }
  }

  &__name {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid transparentize(#ffffff, 0.8);

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__link {
    transition: opacity 0.5s;
    margin-left: auto;
    display: flex;
    align-items: center;

    svg {
      margin-left: 16px;

      @media (max-width: $mobile) {
        margin-left: 11px;
      }
    }

    &:hover {
      opacity: 0.5;
    }
  }
}
