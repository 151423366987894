@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__iceCream {
    position: absolute;
    left: 8px;
    top: -137px;
    transform: rotate(-45deg);
    width: 58px;
  }
}

.image {
  height: 440px;
}

.block_interesting {
  background: linear-gradient(180deg, #4e92d0 0%, #26649e 100%);
  padding: 85px 100px 85px 300px;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  overflow: hidden;

  @media (max-width: $mobile) {
    padding: 15px;
    & > :nth-child(3) {
      margin-bottom: 225px !important;
    }
  }

  &__image {
    height: 343px;
    position: absolute;
    top: 32px;
    left: 7px;
    z-index: 1;

    @media (max-width: $mobile) {
      height: 171px;
      top: unset;
      bottom: 11px;
      left: calc(50% - 40px);
    }
  }
}
