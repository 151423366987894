@import "variables";

.message {
  $this: &;
  display: flex;
  align-items: flex-start;

  @media (max-width: $mobile) {
    flex-direction: column;
  }

  &_vertical {
    flex-direction: column;

    #{$this}__body {
      width: 100%;

      &::before {
        left: 50%;
        top: 0;
        transform: translate(-50%, -100%);
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent rgba(4, 117, 182, 0.2) transparent;
      }
    }
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    @media (max-width: $mobile) {
      margin-bottom: 0;

      #{$this}__author {
        flex-shrink: 1;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__author {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    flex-shrink: 0;
    width: 70px;

    @media (max-width: $mobile) {
      margin-right: 0px;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;
      width: 100%;
    }

    &__image {
      width: 100%;
      height: 70px;
      border-radius: 10px;

      @media (max-width: $mobile) {
        margin-right: 10px;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }

    &__name {
      font-size: 14px;
      line-height: 1.4;
      text-align: center;
      color: #474b61;
      opacity: 0.5;

      @media (max-width: $mobile) {
        width: auto;
      }
    }
  }

  &__body {
    background: rgba(71, 75, 97, 0.05);
    border: 1px solid rgba(71, 75, 97, 0.2);
    border-radius: 10px;
    flex-grow: 1;
    padding: 25px;
    font-size: 18px;
    line-height: 30px;
    color: #474b61;
    position: relative;
    font-weight: 300;

    @media (max-width: $mobile) {
      background: #fff;
      border: none;
      padding: 0;
      font-size: 14px;
      line-height: 20px;
      font-weight: 300;
    }

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent rgba(4, 117, 182, 0.2) transparent transparent;
      top: 25px;
      left: 0;
      transform: translateX(-100%);
      position: absolute;

      @media (max-width: $mobile) {
        display: none;
      }
    }
  }
}
