@import "variables";

.white {
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 102px;
    background-image: url("../../assets/img/head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.image {
  p {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px 30px;
    background: #000;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
  }
}
