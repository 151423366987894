@import "variables";

.slide {
  padding-top: 81px;

  @media (max-width: $mobile) {
    padding-top: 15px;
  }
}

.title {
  @media (max-width: $mobile) {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}

.text {
  color: #fff;
}

.white {
  position: relative;
  margin-top: 150px;

  @media (max-width: $mobile) {
    margin-top: 50px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 117px;
    background-image: url("../../assets/img/slide-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -58px;
    left: 0;

    @media (max-width: $mobile) {
      display: none;
    }
  }

  &__iceCream {
    position: absolute;
    left: 8px;
    top: -114px;
    transform: rotate(-45deg);
    width: 58px;

    @media (max-width: $mobile) {
      display: none;
    }
  }
}

.block_top {
  margin-top: 60px;
  height: 400px;

  &__vitya {
    position: absolute;
    left: 17.53%;
    bottom: -11.25%;
    height: 97.25%;
  }
}

.block_bottom {
  background: linear-gradient(180deg, #4e92d0 0%, #26649e 100%);
  padding: 50px 100px 150px 300px;
  font-size: 20px;
  line-height: 30px;
  position: relative;

  @media (max-width: $mobile) {
    padding: 15px;
  }

  &::after {
    content: "";
    background: linear-gradient(180deg, rgba(38, 100, 158, 0) 0%, #26649e 100%);
    border-radius: 0px 0px 10px 10px;
    height: 70px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__image {
    position: absolute;
    top: 32px;
    left: -28px;
    z-index: 1;
    width: 292px;
    object-fit: cover;

    @media (max-width: $mobile) {
      width: 197px;
      height: 231px;
      top: unset;
      bottom: 50px;
      left: calc(50% - 98px);
    }
  }
}

.scoopes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 62px;
  font-size: 16px;
  line-height: 30px;
  padding-top: 32px;

  @media (max-width: $mobile) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0px 45px;
    margin-bottom: 280px;
    margin-top: 64px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__image {
      position: relative;
      margin-bottom: 51px;
      display: flex;

      img {
        width: 81px;
      }

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 140px;
        height: 140px;
        border: 1px dashed #ffffff;
        border-radius: 50%;
      }
    }
  }
}
