@import "variables";

.roby {
  position: fixed;
  z-index: 1000;
  left: 3.23%;
  bottom: 6px;

  img {
    width: 118px;

    @media (max-width: $mobile) {
      width: 49px;
    }
  }

  span {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, 0);
    font-size: 32px;
    line-height: 40px;

    @media (max-width: $mobile) {
      font-size: 14px;
      line-height: 18px;
      right: -5px;
      top: 10px;
    }

    &::before {
      top: 35px;

      @media (max-width: $mobile) {
        top: 11px;
      }
    }
  }
}
